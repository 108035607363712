import React from 'react';
import "./Experience.css";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

const Experience = () => {
  return (
    <section id='experience'>
      <h5>My Experience</h5>
      <h2>Education</h2>
      <Swiper className='container experience__container'
        modules={[Pagination]}
        spaceBetween={20}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        <SwiperSlide className='experience'>
          <h5 className='experience__name'>Frontend Developer</h5>
          <small className='experience__review'>
            Codecool - Budapest - 2023 - 2024
          </small>
        </SwiperSlide>

        <SwiperSlide className='experience'>
          <h5 className='experience__name'>Graduation</h5>
          <small className='experience__review'>
            Diósgyőri Gimnázium - Miskolc - 2015 - 2021
          </small>
        </SwiperSlide>
      </Swiper>

      <h2>Work</h2>
      <Swiper className='container experience__container'
        modules={[Pagination]}
        spaceBetween={20}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        <SwiperSlide className='experience'>
          <h5 className='experience__name'>Frontend Developer</h5>
          <small className='experience__review'>
            Pannon Set Kft. - Budapest - Aug 2024 - Present
          </small>
        </SwiperSlide>

        <SwiperSlide className='experience'>
          <h5 className='experience__name'>Sales Professional</h5>
          <small className='experience__review'>
            iStyle Hungary Kft. - Budapest - Mar 2024 - Jul 2024
          </small>
        </SwiperSlide>

        <SwiperSlide className='experience'>
          <h5 className='experience__name'>Shop Assistant</h5>
          <small className='experience__review'>
            iCentre Hungary Kft. - Budapest - Dec 2022 - Mar 2023
          </small>
        </SwiperSlide>

      </Swiper>
    </section>
  );
};

export default Experience;
