import React from 'react'
import Cv from '../../assets/cv.jpg'

const CTA = () => {
  return (
    <div className='cta'>
      <a href={Cv} download className='btn'>Download CV</a>
      <a href='#contact' className='btn btn-primary'>Contact me</a>
    </div>
  )
}

export default CTA