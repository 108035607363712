import React from 'react';
import './Portfolio.css';
import project1 from '../../assets/project1.jpg';
import project2 from '../../assets/project2.jpg';
import project3 from '../../assets/project3.jpg';
import project4 from '../../assets/project4.jpg';
import project5 from '../../assets/project5.jpg';
import project6 from '../../assets/project6.jpg';

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Projects</h5>
      <h2>Portfolio</h2>

      <div className="portfolio__container">
        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={project1} alt="Dog Shelter App" />
          </div>
          <h3>In this app, there are two parts. The first part is for adopting a dog. The second part is for admins to manage the dogs.</h3>
          <div className='portfolio__item-cta'>
            <a href="https://github.com/simonbenii/react-dog-shelter-app" className='btn' target="_blank" rel="noopener noreferrer">Github</a>
            <a href="https://simonbenii.github.io/react-dog-shelter-app/" className='btn btn-primary' target='_blank' rel="noopener noreferrer">Live Demo</a>
          </div>

        </article>

        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={project2} alt="Pizza Order App" />
          </div>
          <h3>This app has two parts. The first part is for ordering pizzas. The second part is for admins to view all orders.</h3>
          <div className='portfolio__item-cta'>
            <a href="https://github.com/simonbenii/pizza-order-app" className='btn' target="_blank" rel="noopener noreferrer">Github</a>
            <a href="https://simonbenii-pizza-order-app.onrender.com" className='btn btn-primary' target='_blank' rel="noopener noreferrer">Live Demo</a>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={project3} alt="Pokemon App" />
          </div>
          <h3>This app allows you to choose areas and view all the Pokémon in that area. You can also manage your own Pokémon and engage in battles.</h3>
          <div className='portfolio__item-cta'>
            <a href="https://github.com/simonbenii/pokemon-app-2.0" className='btn' target="_blank" rel="noopener noreferrer">Github</a>
            <a href="https://simonbenii.github.io/pokemon-app-2.0/" className='btn btn-primary' target='_blank' rel="noopener noreferrer">Live Demo</a>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={project4} alt="Nasa App" />
          </div>
          <h3>In this app you can see the NASA daily pictures with a little discription.</h3>
          <div className='portfolio__item-cta'>
            <a href="https://github.com/simonbenii/nasa-app" className='btn' target="_blank" rel="noopener noreferrer">Github</a>
            <a href="https://simonbenii.github.io/nasa-app/" className='btn btn-primary' target='_blank' rel="noopener noreferrer">Live Demo</a>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={project5} alt="Weather App" />
          </div>
          <h3>In this app you can check whats the weather like.</h3>
          <div className='portfolio__item-cta'>
            <a href="https://github.com/simonbenii/weather-app" className='btn' target="_blank" rel="noopener noreferrer">Github</a>
            <a href="https://simonbenii.github.io/weather-app/" className='btn btn-primary' target='_blank' rel="noopener noreferrer">Live Demo</a>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={project6} alt="Rick & Morty" />
          </div>
          <h3>In this app you can see the Rick and Morty characters and check some details about the characters.</h3>
          <div className='portfolio__item-cta'>
            <a href="https://github.com/simonbenii/rick-and-morty" className='btn' target="_blank" rel="noopener noreferrer">Github</a>
            <a href="https://simonbenii.github.io/rick-and-morty/" className='btn btn-primary' target='_blank' rel="noopener noreferrer">Live Demo</a>
          </div>
        </article>

      </div>
    </section>
  );
}

export default Portfolio;
