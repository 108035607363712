import React from 'react'
import './Skills.css'
import { BsCheckCircle } from 'react-icons/bs'

const Skills = () => {
  return (
    <section id='skills'>
      <h5>What Skills I Have</h5>
      <h2>My Skills</h2>

      <div className="container skills__container">
        <div className='skills__frontend'>
          <h3>Frontend Development</h3>
          <div className="skills__conent">
            <article className='skills__details'>
              <BsCheckCircle className='skills__details-icon' />
              <h4>HTML</h4>
            </article>
            <article className='skills__details'>
              <BsCheckCircle className='skills__details-icon' />
              <h4>CSS</h4>
            </article>
            <article className='skills__details'>
              <BsCheckCircle className='skills__details-icon' />
              <h4>JavaScropt</h4>
            </article>
            <article className='skills__details'>
              <BsCheckCircle className='skills__details-icon' />
              <h4>React</h4>
            </article>
            <article className='skills__details'>
              <BsCheckCircle className='skills__details-icon' />
              <h4>Vite</h4>
            </article>
            <article className='skills__details'>
              <BsCheckCircle className='skills__details-icon' />
              <h4>Bootstrap</h4>
            </article>
            <article className='skills__details'>
              <BsCheckCircle className='skills__details-icon' />
              <h4>Tailwind</h4>
            </article>
          </div>
        </div>
        <div className='skills__backend'>
          <h3>Backend Development & Tools</h3>
          <div className="skills__conent">
            <article className='skills__details'>
              <BsCheckCircle className='skills__details-icon' />
              <h4>Express.js</h4>
            </article>
            <article className='skills__details'>
              <BsCheckCircle className='skills__details-icon' />
              <h4>Node.js</h4>
            </article>
            <article className='skills__details'>
              <BsCheckCircle className='skills__details-icon' />
              <h4>MongoDB</h4>
            </article>
            <article className='skills__details'>
              <BsCheckCircle className='skills__details-icon' />
              <h4>Firebase</h4>
            </article>
            <article className='skills__details'>
              <BsCheckCircle className='skills__details-icon' />
              <h4>Postman</h4>
            </article>
            <article className='skills__details'>
              <BsCheckCircle className='skills__details-icon' />
              <h4>npm</h4>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Skills