import React, { useState, useEffect } from 'react';
import { AiOutlineHome, AiOutlineUser } from 'react-icons/ai';
import { BiBook, BiMessageSquareDetail } from 'react-icons/bi';
import { MdSchool } from 'react-icons/md';
import { IoIosAlbums } from 'react-icons/io';
import './Nav.css';

const Navbar = () => {
  const [active, setActive] = useState(window.location.hash || '#');

  useEffect(() => {
    const handleHashChange = () => {
      const currentHash = window.location.hash || '#';
      setActive(currentHash);
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const id = entry.target.getAttribute('id');
            setActive(`#${id}`);
            window.history.pushState(null, null, `#${id}`);
          }
        });
      },
      { threshold: 0.5 } 
    );

    const sections = document.querySelectorAll('section');
    sections.forEach((section) => observer.observe(section));

    const handleScroll = () => {
      if (window.scrollY === 0) {
        setActive('#');
        window.history.pushState(null, null, '#');
      }
    };

    window.addEventListener('scroll', handleScroll);

    handleHashChange();

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
      window.removeEventListener('scroll', handleScroll);
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  return (
    <nav>
      <a
        href="#"
        onClick={() => setActive('#')}
        className={active === '#' ? 'active' : ''}
      >
        <AiOutlineHome />
      </a>
      <a
        href="#about"
        onClick={() => setActive('#about')}
        className={active === '#about' ? 'active' : ''}
      >
        <AiOutlineUser />
      </a>
      <a
        href="#skills"
        onClick={() => setActive('#skills')}
        className={active === '#skills' ? 'active' : ''}
      >
        <BiBook />
      </a>
      <a
        href="#experience"
        onClick={() => setActive('#experience')}
        className={active === '#experience' ? 'active' : ''}
      >
        <MdSchool />
      </a>
      <a
        href="#portfolio"
        onClick={() => setActive('#portfolio')}
        className={active === '#portfolio' ? 'active' : ''}
      >
        <IoIosAlbums />
      </a>
      <a
        href="#contact"
        onClick={() => setActive('#contact')}
        className={active === '#contact' ? 'active' : ''}
      >
        <BiMessageSquareDetail />
      </a>
    </nav>
  );
};

export default Navbar;
